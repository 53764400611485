import { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import AppRouter from './components/AppRouter';
import {Context} from '.';
import useWindowDimensions from './core/hooks/useWindowDimensions';

import './styles/App.scss';
import {FloatButton} from 'antd';
import {CommentOutlined, CustomerServiceOutlined} from '@ant-design/icons';

const App: FC = () => {

  const { store } = useContext(Context);
  
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    console.log(width);
    if (width <= 960) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }
  }, [width, height]);

  useEffect(() => {
    if (width <= 960) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }

    setTimeout(() => {
      store.setIsFaderShown(false);
      setTimeout(() => {
        store.setIsFaderVisible(false);
      }, 400);
    }, 1300);
  }, []);


  return (
    <>
      <div className="fader" style={{display: store.isFaderVisible ? "block" : "none", opacity: store.isFaderShown ? "1" : "0"}}></div>
        
      <AppRouter />

      <FloatButton.Group
        trigger="click"
        type="primary"
        style={{ right: 24, bottom: 24 }}
        icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.75 11.25 15.45 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM264 288h-112C138.8 288 128 277.3 128 264S138.8 240 152 240h112C277.3 240 288 250.8 288 264S277.3 288 264 288zM360 192h-208C138.8 192 128 181.3 128 168S138.8 144 152 144h208C373.3 144 384 154.8 384 168S373.3 192 360 192z"/></svg>}>
        <FloatButton onClick={() => window.location.href = "tel:+78002228798"} icon={<i className="fas fa-phone"></i>} />
        <FloatButton onClick={() => window.location.href = "https://wa.me/send?phone=79380338099"} icon={<i className="fab fa-whatsapp"></i>} />
        <FloatButton onClick={() => window.location.href = "mailto:detox@royalresort.ru"} icon={<i className="fas fa-envelope"></i>} />
      </FloatButton.Group>
    </>
  )
}

export default observer(App)
