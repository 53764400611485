import React from "react";

const Home = React.lazy(() => import("../../pages/Home"));
const SubPage = React.lazy(() => import("../../pages/SubPage"));

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  SUBPAGE = '/subpage',
  
  ABOUT = '/about',
  WHAT_DETOX = '/what_is_detox',
  PROGRAMS = '/programs',
  PROGRAMS_DETOX = '/programs/detox',
  PROGRAMS_POST_COVID = '/programs/post_covid',
  PROCEDURES = '/procedures',
  EATING = '/eating',
  LIVING = '/living',
  SPA = '/spa',
  TOXIC_TEST = '/intoxic',
  READY_FOR_PROCEDURES = '/ready',
  SPECIALISTS = '/specialists',
  PRICES = '/prices',
  REVIEWS = '/reviews',
  CONTACTS = '/contacts',
  FAQ = '/faq',
  MENU = '/menu',
  BOOKING = '/booking'
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.SUBPAGE, exact: true, component: <SubPage /> },
  
  { path: RouteNames.ABOUT, exact: true, component: <SubPage /> },
  { path: RouteNames.WHAT_DETOX, exact: true, component: <SubPage /> },
  { path: RouteNames.PROGRAMS, exact: true, component: <SubPage /> },
  { path: RouteNames.PROCEDURES, exact: true, component: <SubPage /> },
  { path: RouteNames.EATING, exact: true, component: <SubPage /> },
  { path: RouteNames.LIVING, exact: true, component: <SubPage /> },
  { path: RouteNames.SPA, exact: true, component: <SubPage /> },
  { path: RouteNames.TOXIC_TEST, exact: true, component: <SubPage /> },
  { path: RouteNames.READY_FOR_PROCEDURES, exact: true, component: <SubPage /> },
  { path: RouteNames.SPECIALISTS, exact: true, component: <SubPage /> },
  { path: RouteNames.PRICES, exact: true, component: <SubPage /> },
  { path: RouteNames.REVIEWS, exact: true, component: <SubPage /> },
  { path: RouteNames.CONTACTS, exact: true, component: <SubPage /> },
  { path: RouteNames.FAQ, exact: true, component: <SubPage /> },
  { path: RouteNames.MENU, exact: true, component: <SubPage /> },
  { path: RouteNames.BOOKING, exact: true, component: <SubPage /> },
  { path: RouteNames.PROGRAMS_DETOX, exact: true, component: <SubPage /> },
  { path: RouteNames.PROGRAMS_POST_COVID, exact: true, component: <SubPage /> }
]

export const twRoutes: IRoute[ ] = []
